<template>
  <!--内容-->
  <div class="page-schemeDetail">
    <div class="gk-container gk-clear">
      <div class="detail-content">
        <h1>{{ detail.title }}</h1>
        <div class="desc">
          <div class="tag">{{ detail.tags }}</div>
          <div class="static">
            <span><img src="/static/iconImg/icon-view02.png" alt="">{{ detail.view }}</span>
            <span><img src="/static/iconImg/icon-support.png" alt="">{{ detail.very_good }}</span>
            <span><img src="/static/iconImg/icon-comment.png" alt="">{{ detail.comment }}</span>
          </div>
        </div>
        <div class="detail">
          <div v-html="detail.content"></div>
        </div>
        <!--   点赞-->
<!--        <div class="btn-like" @click="veryGood"><img src="/static/iconImg/icon-support2.png" alt="点赞"></div>-->
        <!--  举报等操作   -->
        <div class="detail-op-btns">
          <div class="left"><img src="/static/iconImg/icon-danger.png" alt="">举报</div>
          <div class="right">
<!--            <span class="this-mouse-pointer"><img src="/static/iconImg/icon-share.png" alt="">分享</span>-->
          </div>
        </div>
        <!--  评论区域-->
<!--        <div class="comment-mod">
          <div class="tit">评论</div>
          <div class="comment-cont">
            <img class="advater" src="/static/upload/advater.png" alt="">
            <div class="det">
              <input type="text" placeholder="说两句吧~">
              <span>发布</span>
            </div>
          </div>
        </div>-->
      </div>
      <div class="side-bar">
        <!--   喜欢该作品的还看了 列表-->
        <div class="relate-list">
          <div class="tit">相关文档</div>
          <ul>
            <li v-for="(m,index) in like_data" v-bind:key="index">
              <div class="txt">
                <h3><router-link :to="'/detail/'+m.id">{{ m.title }}</router-link></h3>
              </div>
            </li>
          </ul>
        </div>

      </div>
    </div>
  </div>
</template>
<script>
import page from "@/api/page";

export default {
  name: "detail",
  components: {},
  data() {
    return {
      ident: '',
      detail: {
        file:[],
        user_id:0
      },
      user:{
        username: "用户名",
        nickname: "昵称",
        avatar: "https://3x.thisadmin.cn/static/images/tx.jpg",
        gender: "男"
      },
      attention_txt:"  + 关注",
      favorite_txt:"收藏",
      like_data: [],
      authorStatistics:[],
      loading: false,
    }
  },
  created() {
    this.ident = this.$route.params.ident;
    this.getDetail();
  },
  watch: {
    '$route.params.id': {
      immediate: true, // 立即触发
      handler(newVal, oldVal) {
        // 当id变化时，你可以在这里执行你的逻辑
        console.log('id changed from ' + oldVal + ' to ' + newVal);
        if(newVal!=undefined && newVal>0){
          this.id = newVal;
          this.getDetail();
        }
      }
    }
  },
  methods: {
    //登录框
    showLogin() {
      this.$store.dispatch('toggleLoginModal');
    },
    getDetail() {
      page.getDetailByIdent({
        ident: this.ident
      }).then(res => {
        if (res) {
          this.detail = res.data;
          this.getUserLike();
        }
      })
    },
    //推荐看
    getUserLike() {
      page.getListRow({
        cid: this.detail.cid
      }).then(res => {
        if (res) {
          this.like_data = res.data;
        }
      })
    },
  }
}
</script>
<style lang="scss" scoped>
.this-mouse-pointer {
  cursor: pointer;
}
</style>