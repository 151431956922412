/*文章接口*/

import {req} from '../utils/request';

export class Page {

  /**
   * 获取 首页推荐资讯
   * @param params
   * @returns {Promise<*>}
   */
  getTopList = async (params) => {
    return req.get('user.page/getTopList', params);
  }

  /**
   * 获取 信息列表 前多少条
   * @param params
   * @returns {Promise<*>}
   */
  getListRow = async (params) => {
    return req.get('user.page/getListRow', params);
  }

  /**
   * 获取 信息列表 分页
   * @param params
   * @returns {Promise<*>}
   */
  getList = async (params) => {
    return req.get('user.page/getList', params);
  }

  /**
   * 获取 信息详情
   * @param params
   * @returns {Promise<*>}
   */
  getDetail = async (params) => {
    return req.get('user.page/getDetail', params);
  }

  /**
   * 获取 信息详情
   * @param params
   * @returns {Promise<*>}
   */
  getDetailByIdent = async (params) => {
    return req.get('user.page/getDetailByIdent', params);
  }


}

export default new Page();
